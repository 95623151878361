import { createRouter, createWebHistory } from "vue-router";
import api from "@/boot/axios.js";

const updateUserRolesAndGroups = async () => {
  try {
    const userRoleIds = localStorage.getItem("roleIds");
    const response = await api.get(`auth/roles/?id=${{ userRoleIds }}`);
    const rolesData = response.data;
    const userRoles = rolesData.filter((role) => userRoleIds.includes(role.id));
    const roles = userRoles.map((role) => role.name);
    const groups = userRoles.flatMap((role) =>
      role.groups.map((group) => group.name)
    );
    const uniqueGroups = [...new Set(groups)];

    const currentGroups = JSON.parse(localStorage.getItem("groups") || "[]");
    const groupsChanged =
      JSON.stringify(currentGroups) !== JSON.stringify(uniqueGroups);

    if (groupsChanged) {
      localStorage.setItem("groups", JSON.stringify(uniqueGroups));
      console.log("Groups updated:", uniqueGroups);
    }

    return { roles, groups: uniqueGroups, groupsChanged };
  } catch (error) {
    console.error("Error fetching roles:", error);
    return { roles: [], groups: [], groupsChanged: false };
  }
};

function guardMyroute(to, from, next) {
  var isAuthenticated = false;
  var userGroups = [];

  if (localStorage.jwt) {
    const jwtPayload = parseJwt(localStorage.jwt);

    if (jwtPayload.exp && jwtPayload.exp < Date.now() / 1000) {
      // Token has expired
      deleteTokenFromLocalStorage();
      isAuthenticated = false;
    } else {
      isAuthenticated = true;
      // Get latest user groups from localStorage
      const groupsString = localStorage.getItem("groups");
      userGroups = groupsString ? JSON.parse(groupsString) : [];
    }
  } else {
    isAuthenticated = false;
  }

  if (isAuthenticated) {
    // Check if the route requires specific group access
    if (to.meta.requiredGroups) {
      const hasRequiredGroup = to.meta.requiredGroups.some(
        (group) => userGroups.includes(group) || group === "All"
      );
      if (hasRequiredGroup) {
        next();
      } else {
        // Redirect to home if user doesn't have required group
        console.log(
          `User with groups [${userGroups.join(", ")}] attempted to access ${
            to.path
          }`
        );
        next("/home");
      }
    } else {
      next();
    }
  } else {
    next("/");
  }
}

// Todo
// Network Check fuction implementation
// Like create a dummy api in the backend just to check if i am getting any responses from it
// It will ensure that the server from the backend is running or not

// parse jwt payload
function parseJwt(token) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(atob(base64));
  return JSON.parse(jsonPayload);
}

// delete token
function deleteTokenFromLocalStorage() {
  localStorage.removeItem("jwt");
}

const routes = [
  {
    path: "/",
    name: "login",
    component: () => import("../components/auth/loginView.vue"),
  },

  // pageless path
  {
    path: "/checkout/:id",
    name: "checkout",
    component: () => import("../views/Checkout/checkoutView.vue"),
    beforeEnter: guardMyroute,
  },

  // auth/forget-password

  {
    path: "/forget-password",
    name: "forget-password",
    component: () =>
      import("../components/auth/forget_&_reset_password_view.vue"),
  },

  // profile-page
  {
    path: "/profile/:id",
    name: "profile",
    component: () => import("../views/Profile/profileView.vue"),
    beforeEnter: guardMyroute,
  },

  // home
  {
    path: "/home",
    name: "home",

    component: () => import("../views/Home/home_view.vue"),
    beforeEnter: guardMyroute,
    meta: { requiredGroups: ["All"] },
  },
  // dashboard
  {
    path: "/dashboard",
    name: "dashboard",

    component: () => import("../views/Dashboard/dashboardView.vue"),
    beforeEnter: guardMyroute,
    meta: { requiredGroups: ["Dashboard"] },
  },

  // settings
  {
    path: "/settings",
    name: "settings",

    component: () => import("../views/Settings/settingsView.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/user_settings_view",
    name: "user_settings_view",

    component: () => import("../views/Settings/user_settings_view.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/role_list",
    name: "role_list",

    component: () => import("../views/Settings/role_list_view.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/room_settings",
    name: "room_settings",

    component: () =>
      import("../components/settingComp/roomSetting/roomSettingView.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/vat_service_charge_settings",
    name: "vat_service_charge_settings",

    component: () =>
      import(
        "../components/settingComp/vat_&_service_charge_Setting/vat_&_service_charge_settingView.vue"
      ),
    beforeEnter: guardMyroute,
  },

  // Accounts

  {
    path: "/transaction",
    name: "transaction",

    component: () =>
      import("../views/Accounts/Transcation/transaction_View.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/expenses",
    name: "expenses",

    component: () => import("../views/Accounts/expenses_View.vue"),
    beforeEnter: guardMyroute,
  },

  // Reservation
  {
    path: "/reservation",
    name: "reservation",

    component: () => import("../views/Reservation/reservationView.vue"),
    beforeEnter: guardMyroute,
    meta: { requiredGroups: ["Reservation"] },
  },
  // Room-Transer
  {
    path: "/room_transfer",
    name: "room_transfer",

    component: () => import("../views/Room_Transfer/roomTransferView.vue"),
    beforeEnter: guardMyroute,
    meta: { requiredGroups: ["Room_Transfer"] },
  },
  // Inventory
  {
    path: "/inventory_all_Item",
    name: "inventory_all_Item",

    component: () => import("../views/Inventory/inventory_all_Item_View.vue"),
    beforeEnter: guardMyroute,
  },

  // Reports
  {
    path: "/reports",
    name: "reports",

    component: () => import("../views/Reports/reportView.vue"),
    beforeEnter: guardMyroute,
    meta: { requiredGroups: ["Reports"] },
  },
  {
    path: "/checkincheckout",
    name: "checkincheckout",

    component: () => import("../components/reportComp/checkInCheckOutView.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/customerreport",
    name: "customerreport",

    component: () => import("../components/reportComp/customerReportView.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/room_transfer_report",
    name: "room_transfer_report",

    component: () =>
      import("../components/reportComp/roomTransferReportView.vue"),
    beforeEnter: guardMyroute,
  },

  // Services
  {
    path: "/laundry",
    name: "laundry",

    component: () => import("../views/Service/laundryView.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/maintance",
    name: "maintance",

    component: () => import("../views/Service/maintanceView.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/room_service",
    name: "room_service",

    component: () => import("../views/Service/room_serviceView.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/house_keeping",
    name: "house_keeping",

    component: () => import("../views/Service/house_keeping_View.vue"),
    beforeEnter: guardMyroute,
  },

  // 404

  {
    path: "/:pathMatch(.*)*",
    component: () => import("@/views/NotFound/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.path !== "/" && localStorage.jwt) {
    const { groups, groupsChanged } = await updateUserRolesAndGroups();
    if (groupsChanged) {
      if (to.meta.requiredGroups) {
        const hasRequiredGroup = to.meta.requiredGroups.some(
          (group) => groups.includes(group) || group === "All"
        );
        if (!hasRequiredGroup) {
          // Redirect to home w
          console.log(
            `User's groups changed. No longer has access to ${to.path}`
          );
          next("/home");
          return;
        }
      }
    }
    guardMyroute(to, from, next);
  } else {
    next();
  }
});

export default router;
