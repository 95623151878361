import { createStore } from "vuex";
import { ref, computed } from "vue";
import api from "@/boot/axios.js";

export const collapsed = ref(false);
export const toggleSidebar = () => {
  collapsed.value = !collapsed.value;
};

export const SIDEBAR_WIDTH = 250;
export const SIDEBAR_WIDTH_COLLAPSED = 38;
export const sidebarwidth = computed(
  () => `${collapsed.value ? SIDEBAR_WIDTH_COLLAPSED : SIDEBAR_WIDTH}px`
);

export const openSection = ref(null);
export const isClosing = ref(false);
export function toggleSection(section) {
  openSection.value = openSection.value === section ? null : section;
}

export const showPopup = ref(false);

export function closeSections() {
  isClosing.value = true;
  setTimeout(() => {
    openSection.value = null;
    isClosing.value = false;
  }, 300);
}

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
});
